@font-face {
    font-family: 'Chillax-Variable';
    src: url('../public/fonts/Chillax-Variable.woff2') format('woff2'),
    url('../public/fonts/Chillax-Variable.woff') format('woff'),
    url('../public/fonts/Chillax-Variable.ttf') format('truetype');
    font-weight: 200 700;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Chillax-Extralight';
    src: url('../public/fonts/Chillax-Extralight.woff2') format('woff2'),
    url('../public/fonts/Chillax-Extralight.woff') format('woff'),
    url('../public/fonts/Chillax-Extralight.ttf') format('truetype');
    font-weight: 200;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Chillax-Light';
    src: url('../public/fonts/Chillax-Light.woff2') format('woff2'),
    url('../public/fonts/Chillax-Light.woff') format('woff'),
    url('../public/fonts/Chillax-Light.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Chillax-Regular';
    src: url('../public/fonts/Chillax-Regular.woff2') format('woff2'),
    url('../public/fonts/Chillax-Regular.woff') format('woff'),
    url('../public/fonts/Chillax-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Chillax-Medium';
    src: url('../public/fonts/Chillax-Medium.woff2') format('woff2'),
    url('../public/fonts/Chillax-Medium.woff') format('woff'),
    url('../public/fonts/Chillax-Medium.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Chillax-Semibold';
    src: url('../public/fonts/Chillax-Semibold.woff2') format('woff2'),
    url('../public/fonts/Chillax-Semibold.woff') format('woff'),
    url('../public/fonts/Chillax-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-display: swap;
    font-style: normal;
}


@font-face {
    font-family: 'Chillax-Bold';
    src: url('../public/fonts/Chillax-Bold.woff2') format('woff2'),
    url('../public/fonts/Chillax-Bold.woff') format('woff'),
    url('../public/fonts/Chillax-Bold.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}

@media (min-width: 768px) {
    body {
        background-image: url("../public/img/bg.png");
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
    }
}

/*@media (max-width: 768px) {*/
/*    body {*/
/*        background-image: url("../public/img/bg.png");*/
/*        background-size: 100%;*/
/*    }*/
/*}*/

body {
    margin: 0;
    padding: 0;
}

.chillax {
    font-family: 'Chillax-Regular';
}

.chillax-semi-bold {
    font-family: 'Chillax-Semibold';
}

.chillax-bold {
    font-family: 'Chillax-Bold';
}

.dark-copay-blue {
    color: #031926 !important;
}

.bg-dark-copay-blue {
    background-color: #031926 !important;
}

.copay-blue {
    color: #076df3 !important;
}

.bg-copay-blue {
    background-color: #076df3 !important;
}

.wave-group {
    position: relative;
}

.wave-group .input {
    font-size: 16px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid #031926;
    background: transparent;
}

.wave-group .input:focus {
    outline: none;
}

.wave-group .label {
    color: #999;
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    display: flex;
}

.wave-group .label-char {
    transition: 0.2s ease all;
    /*transition-delay: calc(var(--index) * .05s);*/
}

.wave-group .input:focus ~ label .label-char,
.wave-group .input.not-empty ~ label .label-char {
    transform: translateY(-20px);
    font-size: 14px;
    color: #031926;
}

.wave-group .bar {
    position: relative;
    display: block;
    width: 100%;
}

.wave-group .bar:before, .wave-group .bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: #076df3;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.wave-group .bar:before {
    left: 50%;
}

.wave-group .bar:after {
    right: 50%;
}

.wave-group .input:focus ~ .bar:before,
.wave-group .input:focus ~ .bar:after {
    width: 50%;
}


button {
    --color: #076df3;
    --dark-color: #031926;
    font-family: inherit;
    display: inline-block;
    width: 100%;
    height: 2.6em;
    line-height: 2.5em;
    /*margin: 20px;*/
    position: relative;
    overflow: hidden;
    border: 2px solid var(--color);
    transition: color .5s;
    z-index: 1;
    font-size: 17px;
    border-radius: 6px;
    font-weight: 500;
    background-color: var(--color);
    color: var(--dark-color);
}

button:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: var(--dark-color);
    height: 150px;
    width: 115%;
    border-radius: 50%;
}

button:hover {
    color: #fff;
}

button:before {
    top: 100%;
    left: 100%;
    transition: all .5s;
}

button:hover:before {
    top: -50px;
    left: -25px;
}

button:active:before {
    background: #3a0ca3;
    transition: background 0s;
}

.loader {
    position: relative;
    width: 75px;
    height: 100px;
}

.loader__bar {
    position: absolute;
    bottom: 0;
    width: 10px;
    height: 50%;
    background: #031926;
    transform-origin: center bottom;
    box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.loader__bar:nth-child(1) {
    left: 0px;
    transform: scale(1, 0.2);
    -webkit-animation: barUp1 4s infinite;
    animation: barUp1 4s infinite;
}

.loader__bar:nth-child(2) {
    left: 15px;
    transform: scale(1, 0.4);
    -webkit-animation: barUp2 4s infinite;
    animation: barUp2 4s infinite;
}

.loader__bar:nth-child(3) {
    left: 30px;
    transform: scale(1, 0.6);
    -webkit-animation: barUp3 4s infinite;
    animation: barUp3 4s infinite;
}

.loader__bar:nth-child(4) {
    left: 45px;
    transform: scale(1, 0.8);
    -webkit-animation: barUp4 4s infinite;
    animation: barUp4 4s infinite;
}

.loader__bar:nth-child(5) {
    left: 60px;
    transform: scale(1, 1);
    -webkit-animation: barUp5 4s infinite;
    animation: barUp5 4s infinite;
}

.loader__ball {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 10px;
    height: 10px;
    background: #076df3;
    border-radius: 50%;
    -webkit-animation: ball624 4s infinite;
    animation: ball624 4s infinite;
}

@keyframes ball624 {
    0% {
        transform: translate(0, 0);
    }

    5% {
        transform: translate(8px, -14px);
    }

    10% {
        transform: translate(15px, -10px);
    }

    17% {
        transform: translate(23px, -24px);
    }

    20% {
        transform: translate(30px, -20px);
    }

    27% {
        transform: translate(38px, -34px);
    }

    30% {
        transform: translate(45px, -30px);
    }

    37% {
        transform: translate(53px, -44px);
    }

    40% {
        transform: translate(60px, -40px);
    }

    50% {
        transform: translate(60px, 0);
    }

    57% {
        transform: translate(53px, -14px);
    }

    60% {
        transform: translate(45px, -10px);
    }

    67% {
        transform: translate(37px, -24px);
    }

    70% {
        transform: translate(30px, -20px);
    }

    77% {
        transform: translate(22px, -34px);
    }

    80% {
        transform: translate(15px, -30px);
    }

    87% {
        transform: translate(7px, -44px);
    }

    90% {
        transform: translate(0, -40px);
    }

    100% {
        transform: translate(0, 0);
    }
}

@-webkit-keyframes barUp1 {
    0% {
        transform: scale(1, 0.2);
    }

    40% {
        transform: scale(1, 0.2);
    }

    50% {
        transform: scale(1, 1);
    }

    90% {
        transform: scale(1, 1);
    }

    100% {
        transform: scale(1, 0.2);
    }
}

@keyframes barUp1 {
    0% {
        transform: scale(1, 0.2);
    }

    40% {
        transform: scale(1, 0.2);
    }

    50% {
        transform: scale(1, 1);
    }

    90% {
        transform: scale(1, 1);
    }

    100% {
        transform: scale(1, 0.2);
    }
}

@-webkit-keyframes barUp2 {
    0% {
        transform: scale(1, 0.4);
    }

    40% {
        transform: scale(1, 0.4);
    }

    50% {
        transform: scale(1, 0.8);
    }

    90% {
        transform: scale(1, 0.8);
    }

    100% {
        transform: scale(1, 0.4);
    }
}

@keyframes barUp2 {
    0% {
        transform: scale(1, 0.4);
    }

    40% {
        transform: scale(1, 0.4);
    }

    50% {
        transform: scale(1, 0.8);
    }

    90% {
        transform: scale(1, 0.8);
    }

    100% {
        transform: scale(1, 0.4);
    }
}

@-webkit-keyframes barUp3 {
    0% {
        transform: scale(1, 0.6);
    }

    100% {
        transform: scale(1, 0.6);
    }
}

@keyframes barUp3 {
    0% {
        transform: scale(1, 0.6);
    }

    100% {
        transform: scale(1, 0.6);
    }
}

@-webkit-keyframes barUp4 {
    0% {
        transform: scale(1, 0.8);
    }

    40% {
        transform: scale(1, 0.8);
    }

    50% {
        transform: scale(1, 0.4);
    }

    90% {
        transform: scale(1, 0.4);
    }

    100% {
        transform: scale(1, 0.8);
    }
}

@keyframes barUp4 {
    0% {
        transform: scale(1, 0.8);
    }

    40% {
        transform: scale(1, 0.8);
    }

    50% {
        transform: scale(1, 0.4);
    }

    90% {
        transform: scale(1, 0.4);
    }

    100% {
        transform: scale(1, 0.8);
    }
}

@-webkit-keyframes barUp5 {
    0% {
        transform: scale(1, 1);
    }

    40% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1, 0.2);
    }

    90% {
        transform: scale(1, 0.2);
    }

    100% {
        transform: scale(1, 1);
    }
}

@keyframes barUp5 {
    0% {
        transform: scale(1, 1);
    }

    40% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1, 0.2);
    }

    90% {
        transform: scale(1, 0.2);
    }

    100% {
        transform: scale(1, 1);
    }
}